<template>
  <div class="column is-half box p-5">
    <!-- Completion Section -->
    <div class="title is-4 heading-font">My Stats</div>
    <span class="heading">Workload Completion</span>
    <b-progress format="percent">
      <template #bar>
        <b-progress-bar
          :value="workloadCompletion"
          type="is-primary"
          show-value
        ></b-progress-bar>
      </template>
    </b-progress>

    <div class="columns">
      <div class="column has-content-centered">
        <div class="pb-3">
          <span class="heading">Estimated Hours Contracted</span>
          <span class="subtitle has-text-primary">{{ estimatedHours }}</span>
        </div>
        <div class="pb-3">
          <span class="heading">Hours Worked</span>
          <span class="subtitle has-text-primary">{{ hoursWorked }}</span>
        </div>
      </div>

      <div class="column">
        <div class="pb-3">
          <span class="heading">Average Hourly Rate</span>
          <span class="subtitle has-text-primary">{{
            formattedAverageHourlyRate
          }}</span>
        </div>
        <div class="pb-3">
          <span class="heading">Total Earnings</span>
          <span class="subtitle has-text-success has-text-weight-bold">{{
            formattedTotalEarnings
          }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { formatCurrency } from "@/utils/currency";
export default {
  name: "StatsBox",
  props: {
    workloadCompletion: {
      type: Number,
      default() {
        return null;
      },
      required: false,
    },
    estimatedHours: {
      type: Number,
      default() {
        return null;
      },
      required: false,
    },
    totalEarnings: {
      type: Number,
      default() {
        return null;
      },
      required: false,
    },
    hoursWorked: {
      type: Number,
      default() {
        return null;
      },
      required: false,
    },
    locale: {
      type: String,
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  computed: {
    formattedAverageHourlyRate() {
      //add null check
      const averageRate = this.totalEarnings / this.hoursWorked;
      return formatCurrency(averageRate, this.locale, this.currency);
    },
    formattedTotalEarnings() {
      //add null check
      return formatCurrency(this.totalEarnings, this.locale, this.currency);
    },
  },
};
</script>
