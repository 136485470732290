<template>
  <div class="section keep-footer-off-page">
    <div class="section">
      <div class="container">
        <div class="columns is-centered">
          <StatsBox
            :workloadCompletion="63"
            :estimatedHours="209"
            :totalEarnings="49293.98"
            :hoursWorked="1938"
            locale="en-US"
            currency="USD"
          />
        </div>
      </div>
    </div>
    <div class="container">
      <div class="tile is-ancestor">
        <div class="tile is-8 is-vertical is-parent">
          <div class="tile is-child box">
            <p class="title">Recent Tasks</p>
            <!-- Task tags -->
            <div class="my-1" v-for="(task, tid) in tasks" :key="tid">
              <SmartTag
                @click.native="navigateToTask(tid)"
                class="is-clickable"
                size="is-small"
                :tags="[
                  { type: 'is-task', value: task.name },
                  { type: 'is-date', value: task.deadlineDate },
                  { type: 'is-skill', value: task.skills[0] },
                ]"
              />
            </div>
          </div>
          <div class="tile is-child box">
            <p class="title">Interesting Jobs</p>
            <p>
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf
            </p>
          </div>
          <div class="is-child box">ojoij</div>
        </div>
        <div class="tile is-parent is-4">
          <div class="tile is-child box">
            <p class="title">Changelog Feed</p>
            <p>
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf
              oijds f fsdoj f joidjf jf oijds f fsdoj f joidjf jf oijds f fsdoj
              f joidjf jf
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import json from "@/assets/dummyData.json";
import SmartTag from "@/components/SmartTag.vue";
import StatsBox from "@/components/StatsBox.vue";
export default {
  name: "dashboard-view",
  components: {
    SmartTag,
    StatsBox,
  },
  data() {
    return {
      projects: json,
      tasks: "",
      projectCompletion: 77,
    };
  },
  methods: {
    async testBackend() {
      let config = { headers: { Accept: "application/json" } };
      const response = await this.$axios.get(
        "https://solute-python3.onrender.com/dev",
        config
      );
      this.message = response.data;
    },
    navigateToTask(tid) {
      this.$router.push({ name: "Task", params: { tid: tid } });
    },
  },
  created() {
    this.tasks = this.projects["-NEqAPHQjEv8H1Djk2P6"]["tasks"];
  },
};
</script>
